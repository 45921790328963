/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
const n = {
  remove: () => {}
};
function e(e) {
  return e ? {
    remove() {
      e && (e(), e = void 0);
    }
  } : n;
}
function r(n) {
  n.forEach(n => n?.remove());
}
function o(n) {
  return e(() => r(n));
}
function t(n) {
  return e(() => n()?.remove());
}
function u(n) {
  return e(() => n?.abort());
}
function l(n) {
  return e(null != n ? () => n.destroy() : void 0);
}
function i(n, r) {
  let o = !1,
    t = null;
  return n.then(n => {
    o ? n.remove() : t = n;
  }), e(() => {
    o = !0, null != t ? t.remove() : null != r && (r.abort(), r = null);
  });
}
function c(n) {
  return {
    [Symbol.dispose]() {
      n.remove();
    }
  };
}
export { u as abortHandle, i as asyncHandle, l as destroyHandle, c as disposable, o as handlesGroup, e as makeHandle, t as refHandle, r as removeHandles };