/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { makeHandle as t } from "./handleUtils.js";
function e(e) {
  return {
    setTimeout: (o, r) => {
      const i = e.setTimeout(o, r);
      return t(() => e.clearTimeout(i));
    }
  };
}
const o = e(globalThis);
export { o as clock, e as wrap };