/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { clock as t } from "./clock.js";
import n from "./Error.js";
import { once as r } from "./events.js";
import e from "./Logger.js";
import { removeMaybe as o } from "./maybe.js";
async function i(t, n) {
  const r = t.slice(),
    e = await Promise.all(t.map((t, r) => n(t, r)));
  return r.filter((t, n) => e[n]);
}
function u(t = "Aborted") {
  return new n("AbortError", t);
}
function s(t, n = "Aborted") {
  if (c(t)) throw u(n);
}
function l(t) {
  return t instanceof AbortSignal ? t : t?.signal;
}
function c(t) {
  const n = l(t);
  return null != n && n.aborted;
}
function a(t) {
  if (b(t)) throw t;
}
function f(t) {
  if (!b(t)) throw t;
}
function m(t, n) {
  const e = l(t);
  if (null != e) {
    if (!e.aborted) return r(e, "abort", () => n());
    n();
  }
}
function w(t, n) {
  const e = l(t);
  if (null != e) return s(e), r(e, "abort", () => n(u()));
}
function h(t, n) {
  return null == l(n) ? t : new Promise((r, e) => {
    let i = m(n, () => e(u()));
    const s = () => {
      i = o(i);
    };
    t.then(s, s), t.then(r, e);
  });
}
function p(t, r, e) {
  return Promise.race([t, A(r).then(() => {
    throw new n("timeout", `Did not resolve within ${r} milliseconds (${e ?? "timeout"})`);
  })]);
}
function b(t) {
  return "AbortError" === t?.name;
}
async function d(t) {
  try {
    return await t;
  } catch (n) {
    if (!b(n)) throw n;
    return;
  }
}
async function y(t, n = e.getLogger("esri")) {
  try {
    return await t;
  } catch (r) {
    b(r) || n.error(r);
  }
}
async function v(t) {
  if (!t) return;
  if ("function" != typeof t.forEach) {
    const n = Object.keys(t),
      r = n.map(n => t[n]),
      e = await v(r),
      o = {};
    return n.map((t, n) => o[t] = e[n]), o;
  }
  const n = t;
  return Promise.allSettled(n).then(t => Array.from(n, (n, r) => {
    const e = t[r];
    return "fulfilled" === e.status ? {
      promise: n,
      value: e.value
    } : {
      promise: n,
      error: e.reason
    };
  }));
}
async function j(t) {
  return (await v(t)).filter(t => !!t.value).map(t => t.value);
}
async function g(t) {
  return (await Promise.allSettled(t)).filter(t => "fulfilled" === t.status).map(t => t.value);
}
async function P(t) {
  return (await Promise.allSettled(t)).filter(t => "rejected" === t.status).map(t => t.reason);
}
function A(t, n = void 0, r) {
  const e = new AbortController();
  return m(r, () => e.abort()), new Promise((r, o) => {
    let i = setTimeout(() => {
      i = 0, r(n);
    }, t);
    m(e, () => {
      i && (clearTimeout(i), o(u()));
    });
  });
}
function T(t, r, e, o) {
  const i = e && "abort" in e ? e : null;
  null != o || i || (o = e);
  let u = setTimeout(() => {
    u = 0, i?.abort();
  }, r);
  const s = () => o || new n("promiseUtils:timeout", "The wrapped promise did not resolve within " + r + " ms");
  return t.then(t => {
    if (0 === u) throw s();
    return clearTimeout(u), t;
  }, t => {
    throw clearTimeout(u), 0 === u ? s() : t;
  });
}
function E(t, n) {
  const r = new AbortController(),
    e = setTimeout(() => r.abort(), n);
  return m(t, () => {
    r.abort(), clearTimeout(e);
  }), {
    ...t,
    signal: r.signal
  };
}
function S(t) {
  return t && "function" == typeof t.then;
}
function C(t) {
  return S(t) ? t : Promise.resolve(t);
}
function k(t, n = -1) {
  let r,
    e,
    o,
    i,
    s = null;
  const l = (...c) => {
    if (r) {
      e = c, i && i.reject(u()), i = L();
      const t = i.promise;
      if (s) {
        const t = s;
        s = null, t.abort();
      }
      return t;
    }
    if (o = i || L(), i = null, n > 0) {
      const e = new AbortController();
      r = C(t(...c, e.signal));
      const o = r;
      A(n).then(() => {
        r === o && (i ? e.abort() : s = e);
      });
    } else r = 1, r = C(t(...c));
    const a = () => {
        const t = e;
        e = o = r = s = null, null != t && l(...t);
      },
      f = r,
      m = o;
    return f.then(a, a), f.then(m.resolve, m.reject), m.promise;
  };
  return l;
}
function L() {
  let n, r;
  const e = new Promise((t, e) => {
      n = t, r = e;
    }),
    o = t => {
      n(t);
    };
  return o.resolve = t => n(t), o.reject = t => r(t), o.timeout = (n, r) => t.setTimeout(() => o.reject(r), n), o.promise = e, o;
}
function $(t, n) {
  return t.then(n, n);
}
function x(t, n) {
  n.then(t.resolve, t.reject);
}
async function D(t) {
  await Promise.resolve(), s(t);
}
export { A as after, P as allSettledErrors, g as allSettledValues, $ as always, u as createAbortError, L as createResolver, k as debounce, v as eachAlways, j as eachAlwaysValues, i as filter, d as ignoreAbortErrors, b as isAbortError, c as isAborted, S as isPromiseLike, y as logOnError, m as onAbort, w as onAbortOrThrow, x as settleWithPromise, l as signalFromSignalOrOptions, a as throwIfAbortError, s as throwIfAborted, f as throwIfNotAbortError, T as timeout, D as waitTick, C as when, h as whenOrAbort, p as whenOrTimeout, E as wrapAbortWithTimeout };